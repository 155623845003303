import * as fromMessage from './../actions/message.actions';

export interface MsgState {
    msg: string;
}

const initState: MsgState = {
    msg: null
};

export function messageReducer( state = initState, action: fromMessage.actions): MsgState {
    switch (action.type) {
        case fromMessage.MESSAGE_SUCCESS:
            return {
                msg: ''
            };
        case fromMessage.MESSAGE_ERROR:
            return {
                msg: 'Usuario o Contraseña Incorrecta'
            };
        default:
            return state;
    }
}
