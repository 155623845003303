import * as fromFixers from '../actions/fixers.actions';
import { Fixer } from 'src/app/helpers/models/fixer';

export interface FixerState {
    fixers: Array<Fixer>;
    fixerClick: Fixer;
    loading: boolean ;
    loaded: boolean;
    error: boolean;
}

const initState: FixerState = {
    fixers: [],
    fixerClick: null,
    loading: false,
    loaded: false,
    error: false,
};

export function fixersReducer( state = initState, action: fromFixers.actions ): FixerState {
    switch (action.type) {

        case fromFixers.LOAD_FIXER:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: false,
            };

        case fromFixers.LOAD_FIXER_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: true,
            };

        case fromFixers.SET_FIXER_CLICK:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                fixerClick: { ...action.fixer }
            };

        case fromFixers.UNSET_FIXER_CLICK:
            return {
                ...state,
                fixerClick: null,
            };

        case fromFixers.SET_FIXERS_LIST:
            return {
                ...state,
                fixers: [
                    ...action.fixers.map(fix => {
                        return {
                            ...fix
                        };
                    })
                ],
            };

        case fromFixers.UNSET_FIXERS_LIST:
            return {
                ...state,
                fixers: []
            };

        default:
            return state;
    }
}
