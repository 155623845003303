import { Action } from '@ngrx/store';
import { FilterUser } from 'src/app/helpers/models/filter-users';

export const FILTER_VALUE_USER = '[USERS] Filter Users Value';
export const LOAD_USERS = '[USERS] Load Users';
export const LOAD_USERS_FAIL = '[USERS] Load Users Fail';
export const LOAD_USERS_SUCCESS = '[USERS] Load Users Success';
export const UNSET_USERS = '[USERS] Unset Users';


export const LOAD_FILTER_USERS = '[USERS] Load Filter Users';
export const LOAD_FILTER_USERS_FAIL = '[USERS] Load Filter Users Fail';
export const LOAD_FILTER_USERS_SUCCESS = '[USERS] Load Filter Users Success';
export const ERROR_FILTER_USERS = '[USERS] Error Filter Users';
export const UNSET_FILTER_USERS = '[FILUSERSTER] Unset Filter Users';

export class FilterValueUser implements Action {
    readonly type = FILTER_VALUE_USER;
}

export class LoadFilterUsers implements Action {
    readonly type = LOAD_FILTER_USERS;
}

export class LoadFilterUsersFail implements Action {
    readonly type = LOAD_FILTER_USERS_FAIL;
}

export class LoadFilterUsersSuccess implements Action {
    readonly type = LOAD_FILTER_USERS_SUCCESS;
    constructor( public filterUsers: Array<FilterUser>) {}
}

export class ErrorFilterUsers implements Action {
    readonly type = ERROR_FILTER_USERS;
}

export class UnsetFilterUsers implements Action {
    readonly type = UNSET_FILTER_USERS;
}


export class LoadUsers implements Action {
    readonly type = LOAD_USERS;
}

export class LoadUsersFail implements Action {
    readonly type = LOAD_USERS_FAIL;
}

export class LoadUsersSuccess implements Action {
    readonly type = LOAD_USERS_SUCCESS;
    constructor( public users: Array<any>) {}
}

export class UnsetUsers implements Action {
    readonly type = UNSET_USERS;
}

export type actions =   LoadUsers |
                        LoadUsersFail |
                        LoadUsersSuccess |
                        UnsetUsers |
                        LoadFilterUsers |
                        LoadFilterUsersFail |
                        LoadFilterUsersSuccess |
                        ErrorFilterUsers |
                        UnsetFilterUsers |
                        FilterValueUser;
