import { Action } from '@ngrx/store';
import { Service  } from 'src/app/helpers/models/services';

export const LOAD_SERVICES = '[SERVICES] Load Services';
export const LOAD_SERVICES_FAIL = '[SERVICES] Load Services FAIL';
export const LOAD_SERVICES_SUCCESS = '[SERVICES] Load Services SUCCESS';
export const UNSET_SERVICES = '[SERVICES] Unset Services';

export class LoadServices implements Action {
  readonly type = LOAD_SERVICES;
}

export class LoadServicesFail implements Action {
  readonly type = LOAD_SERVICES_FAIL;
  constructor( public payload: any) {}
}

export class LoadServicesSuccess implements Action {
  readonly type = LOAD_SERVICES_SUCCESS;
  constructor( public services: Array<Service>) {}
}

export class UnSetServices implements Action {
  readonly type = UNSET_SERVICES;
}


export type actions = LoadServices | LoadServicesFail | LoadServicesSuccess | UnSetServices;
