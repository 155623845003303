import { Action } from '@ngrx/store';
import { Fixer } from 'src/app/helpers/models/fixer';

export const SET_FIXERS_LIST = '[FIXER] Set Fixer List';
export const UNSET_FIXERS_LIST = '[FIXER] Unset Fixer List';

export const SET_FIXER_CLICK = '[FIXER] Set Fixer Click';
export const UNSET_FIXER_CLICK = '[FIXER] Unset Fixer Click';

export const LOAD_FIXER_FAIL = '[FIXER] Load Fixer FAIL';
export const LOAD_FIXER = '[FIXER] Load Fixer';

export class LoadFixer implements Action {
    readonly type = LOAD_FIXER;
}

export class LoadFixerFail implements Action {
    readonly type = LOAD_FIXER_FAIL;
}

export class SetFixerList implements Action {
    readonly type = SET_FIXERS_LIST;
    constructor(public fixers: Array<Fixer>) {}
}

export class UnSetFixerList implements Action {
    readonly type = UNSET_FIXERS_LIST;
}

export class SetFixerClick implements Action {
    readonly type = SET_FIXER_CLICK;
    constructor(public fixer: Fixer) {}
}

export class UnSetFixerClick implements Action {
    readonly type = UNSET_FIXER_CLICK;
}

export type actions =   SetFixerList |
                        UnSetFixerList |
                        SetFixerClick |
                        UnSetFixerClick |
                        LoadFixerFail |
                        LoadFixer;
