import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants/constants';
import { LoadUsers, LoadUsersSuccess, LoadUsersFail, UnsetUsers } from 'src/app/store/actions/users.action';
import { environment } from 'src/environments/environment';
import { UserObj } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  API_URL = environment.server + '/api/v2';

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  readonly authOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('isLoggedin') // + this._userService.token
    })
  };

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient) {
  }

  async getUsers(type: string) {
    try {
      this.store.dispatch(new LoadUsers());
      await this.getClientUsers(this.authOptions, type);

    } catch (error) {
      this.store.dispatch(new LoadUsersFail());
      this.store.dispatch(new UnsetUsers());
    }
  }

  getUsersPaginated(params) {
    const url = this.API_URL + '/admin/users/';
    const options = {...this.authOptions, params};
    return this.httpClient.get(url, options).toPromise();
  }

  async getClientUsers(httpOptions: any, type: string) {
    await this.httpClient.get(this.API_URL + '/users/', httpOptions)
      .toPromise()
        .then( ( provider: any ) => {
          // let users: Array<any>;
          this.store.dispatch(new LoadUsersSuccess(provider));
      },
        ( err ) => {
          this.store.dispatch(new LoadUsersFail());
          throw err;
      }
    );
  }

  getTechnicians(id: number): Promise<UserObj[]> {
    const url = `${this.API_URL}/admin/users/${id}/technicians/`;
    return this.httpClient.get(url, this.authOptions).toPromise() as any;
  }

  async patchUser(id: number, data: any) {
    return await this.httpClient.patch(`${this.API_URL}/users/${id}/`, data,
    this.authOptions).toPromise();
  }

  async patchProfile(id: number, data: any) {
    return await this.httpClient.patch(`${this.API_URL}/profiles/${id}/`, data,
    this.authOptions).toPromise();
  }


}
