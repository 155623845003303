import { Action } from '@ngrx/store';
import { Reservation } from 'src/app/helpers/models/reservation';
import { OrderRequest } from 'src/app/helpers/models/order';

export const SEND_LOAD_RESERVATION = '[RESERVATION] Send Reservation';
export const SEND_LOAD_RESERVATION_FAIL = '[RESERVATION] Send Reservation Fail';
export const SEND_RESERVATION_SUCCESS =  '[RESERVATION] Send Reservation Success';

export const SET_CLICK_RESERVATION = '[RESERVATION] Set Reservation Click';
export const UNSET_CLICK_RESERVATION = '[RESERVATION] UnSet Reservation Click';

export const LOAD_RESERVATION = '[RESERVATION] Load Reservation';
export const LOAD_RESERVATION_FAIL = '[RESERVATION] Load Reservation Fail';
export const LOAD_RESERVATION_SUCCESS = '[RESERVATION] Load Reservation Success';
export const ERROR_RESERVATION = '[RESERVATION] Error Reservation';
export const UNSET_RESERVATION = '[RESERVATION] Unset Reservation Fail';

export class SendReservationSuccess implements Action {
    readonly type = SEND_RESERVATION_SUCCESS;
}

export class SendLoadReservation implements Action {
    readonly type = SEND_LOAD_RESERVATION;
}

export class SendLoadReservationFail implements Action {
    readonly type = SEND_LOAD_RESERVATION_FAIL;
}

export class SetReservationCLick implements Action {
    readonly type = SET_CLICK_RESERVATION;
    constructor(public reservationClick: OrderRequest) {}
}

export class UnSetReservationClick implements Action {
    readonly type = UNSET_CLICK_RESERVATION;
}


export class LoadReservation implements Action {
    readonly type = LOAD_RESERVATION;
}

export class LoadReservationFail implements Action {
    readonly type = LOAD_RESERVATION_FAIL;
}

export class LoadReservationSuccess implements Action {
    readonly type = LOAD_RESERVATION_SUCCESS;
    constructor( public reservation: Array<OrderRequest>) {}
}

export class ErrorReservation implements Action {
    readonly type = ERROR_RESERVATION;
}

export class UnsetReservation implements Action {
    readonly type = UNSET_RESERVATION;
}

export type actions =   LoadReservation |
                        LoadReservationFail |
                        LoadReservationSuccess |
                        LoadReservationSuccess |
                        ErrorReservation |
                        UnsetReservation |
                        SetReservationCLick |
                        UnSetReservationClick |
                        SendLoadReservation |
                        SendLoadReservationFail |
                        SendReservationSuccess;
