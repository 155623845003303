import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { ActivateLoadingAction, DeactivateLoadingAction } from './../../store/actions/ui.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { Auth } from 'src/app/auth/auth.interface';
import { Router } from '@angular/router';
import { SetMessageError, SetMessageSuccess } from 'src/app/store/actions/message.actions';
import { SetUserAction, UnSetUserAction } from 'src/app/store/actions/auth.actions';
import { UnSetServices } from 'src/app/store/actions/services.actions';
import { UnSetFixerList, UnSetFixerClick } from 'src/app/store/actions/fixers.actions';
import { UnsetFilter } from 'src/app/store/actions/filter.actions';
import { UnsetUsers, UnsetFilterUsers } from 'src/app/store/actions/users.action';
import { UsersEnum } from '../enums/users.enum';
import { UnsetReservation, UNSET_CLICK_RESERVATION, UnSetReservationClick } from 'src/app/store/actions/reservation.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient,
              private router: Router) {
                this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('isLoggedin'));
                this.currentUser = this.currentUserSubject.asObservable();
  }

  logIn( user: Auth ) {
    this.store.dispatch(new ActivateLoadingAction());
    const payload = {
      username: user.user,
      password: user.password,
      role: UsersEnum.ADMIN
    };
    this.httpClient.post(
      Constants.SERVER_API_MIGRATION_CLOUD + '/auth/login/', payload
    ).subscribe(
      (res: any) => {
        this.initUser(res.data);
        this.router.navigate(['/']);
        this.store.dispatch(new SetMessageSuccess());
        this.store.dispatch(new DeactivateLoadingAction());
      },
      err => {
        this.store.dispatch(new SetMessageError());
        this.store.dispatch(new DeactivateLoadingAction());
      },
    );
      // () => console.log('asdasd'));
  }

  async isAuth() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + localStorage.getItem('isLoggedin')
      })
    };
    const logged = await this.httpClient.post(Constants.SERVER_API_MIGRATION_CLOUD + '/auth/account/', {}, httpOptions)
    .toPromise().then(
      ( userRes: any ) => {
        if (userRes) {
          this.initUserReload(userRes);
          return true;
        } else {
           return false;
        }
      },
      ( err ) => {
        this.currentUserSubject.next(err);
        return false;
      }
    );
    return logged;
  }

  async initUserReload(userRes: any) {
    userRes.token = localStorage.getItem('isLoggedin');
    const userStore = new User(userRes);
    localStorage.setItem('isLoggedin', userRes.token);
    this.store.dispatch( new SetUserAction(userStore));
  }

  private initUser(userRes: any) {
    const userStore = new User(userRes);
    if (userRes.token) {
      localStorage.setItem('isLoggedin', userRes.token);
    }
    this.store.dispatch( new SetUserAction(userStore));
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  async logout() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + localStorage.getItem('isLoggedin')
      })
    };
    await this.httpClient.post(Constants.SERVER_API_MIGRATION_CLOUD + '/auth/logout/', {}, httpOptions)
    .toPromise().then(
      ( ) => {
          localStorage.removeItem('isLoggedin');
          this.currentUserSubject.next(null);
          this.removeStoreRedux();
          this.router.navigate(['/login']);
        },
      ( err ) => {
        this.currentUserSubject.next(err);
      }
    );
  }

  private removeStoreRedux() {
    this.store.dispatch( new UnSetServices());
    this.store.dispatch( new UnSetUserAction());
    this.store.dispatch( new UnSetFixerList());
    this.store.dispatch( new UnsetFilter());
    this.store.dispatch( new UnsetUsers());
    this.store.dispatch( new UnsetReservation());
    this.store.dispatch( new UnsetFilterUsers());
    this.store.dispatch( new UnSetFixerClick());
    this.store.dispatch( new UnSetReservationClick());
  }

}
