import { ActionReducerMap } from '@ngrx/store';

import * as fromUI from './store/reducers/ui.reducers';
import * as fromAuth from './store/reducers/auth.reducer';
import * as fromMsg from './store/reducers/message.reducer';
import * as fromServices from './store/reducers/services.reducer';
import * as fromFilter from './store/reducers/filter.reducer';
import * as fromFixers from './store/reducers/fixers.reducer';
import * as fromUsers from './store/reducers/users.reducer';
import * as fromReservation from './store/reducers/reservation.reducer';

export interface AppState {
    ui: fromUI.State;
    auth: fromAuth.AuthState;
    msg: fromMsg.MsgState;
    services: fromServices.ServicesState;
    filters: fromFilter.FilterState;
    fixers: fromFixers.FixerState;
    users: fromUsers.UsersState;
    reservations: fromReservation.ReservationState;
}

export const appReducers: ActionReducerMap<AppState> = {
    ui: fromUI.uiReducer,
    auth: fromAuth.authReducer,
    msg: fromMsg.messageReducer,
    services: fromServices.servicesReducer,
    filters: fromFilter.filterReducer,
    fixers: fromFixers.fixersReducer,
    users: fromUsers.usersReducer,
    reservations: fromReservation.reservationReducer,
};
