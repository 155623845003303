import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

interface ListFilterData {
  title: string;
  headers: string[];
  fields: string[];
  listFunction: (p?: any) => any;
  params: any;
  initList: any[];
  delay?: boolean;
}

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent<T> implements OnInit {

  filterControl = new FormControl();
  items = [];
  delayTimer;
  delay = 500;


  doNotSearchKeys = ['Shift', 'Control', ' '];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ListFilterData,
    public dialogRef: MatDialogRef<ListFilterComponent<T>>
    ) { }

  ngOnInit(): void {
    this.items = this.data.initList || [];
    if (!this.data.delay) {
      this.delay = 0;
    }
  }

  resolve(item: T, field: string) {
    return _.get(item, field);
  }

  shouldSearch(event: KeyboardEvent) {
    return !this.doNotSearchKeys.includes(event.key);
  }

  async doSearch() {
    const params = {...this.data.params, identifier: this.filterControl.value};
    const response = await this.data.listFunction(params);
    this.items = response.results;
  }

  delaySearch(event: KeyboardEvent) {
    if (!this.shouldSearch(event)) {
      return;
    }
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.doSearch();
    }, this.delay);
  }


}
