import * as fromFilters from '../actions/filter.actions';
import { Filter } from 'src/app/helpers/models/filter';

export interface FilterState {
    filters: Array<Filter>;
    loaded: boolean;
    loading: boolean;
    fail: boolean;
}

const initState: FilterState = {
    filters: [],
    loaded: false,
    loading: false,
    fail: false,
};

export function filterReducer( state = initState, action: fromFilters.actions ): FilterState {
    switch (action.type) {

        case fromFilters.LOAD_FILTERS:
            return {
                ...state,
                loading: true,
                loaded: false,
                fail: false,
            };

        case fromFilters.LOAD_FILTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                fail: false,
                filters: [
                    ...action.filters.map(filter => {
                        return {
                            ...filter
                        };
                    })
                ],
            };

        case fromFilters.LOAD_FILTERS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                fail: true,
            };

        case fromFilters.UNSET_FILTERS:
            return {
                ...state,
                filters: null
            };

        default:
            return state;
    }
}
