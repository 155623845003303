import * as fromUsers from '../actions/users.action';
import { Provider } from 'src/app/helpers/models/provider';
import { FilterUser } from 'src/app/helpers/models/filter-users';

export interface UsersState {
    users: Array<Provider>;
    filterUserValue: string;
    loading: boolean;
    loaded: boolean;
    error: boolean;
    filterUser: Array<FilterUser>;
    loadingFilter: boolean;
    loadedFilter: boolean;
    errorFilter: boolean;
}

const initState: UsersState = {
    users: [],
    filterUserValue: '',
    loading: false,
    loaded: false,
    error: false,
    filterUser: [],
    loadingFilter: false,
    loadedFilter: false,
    errorFilter: false,
};

export function usersReducer( state = initState, action: fromUsers.actions ): UsersState {
    switch (action.type) {


        case fromUsers.LOAD_FILTER_USERS:
            return {
                ...state,
                loadingFilter: true,
                loadedFilter: false,
                errorFilter: false,
            };

        case fromUsers.LOAD_FILTER_USERS_FAIL:
            return {
                ...state,
                loadingFilter: false,
                loadedFilter: false,
                errorFilter: false,
            };

        case fromUsers.LOAD_FILTER_USERS_SUCCESS:
            return {
                ...state,
                loadingFilter: false,
                loadedFilter: true,
                errorFilter: false,
                filterUser: [
                    ...action.filterUsers.map(filter => {
                        return {
                            ...filter
                        };
                    })
                ]
            };

        case fromUsers.ERROR_FILTER_USERS:
            return {
                ...state,
                loadingFilter: false,
                loadedFilter: false,
                errorFilter: true,
                filterUser: null
            };

        case fromUsers.UNSET_FILTER_USERS:
            return {
                ...state,
                filterUser: null
            };

        case fromUsers.LOAD_USERS:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: false,
            };

        case fromUsers.LOAD_USERS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: true,
            };

        case fromUsers.UNSET_USERS:
        return {
            ...state,
            users: null
        };

        case fromUsers.LOAD_USERS_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: false,
                users: [
                    ...action.users.map(user => {
                        return {
                            ...user
                        };
                    })
                ]
            };
        default:
            return state;
    }
}
