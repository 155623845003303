import * as fromReservation from '../actions/reservation.actions';
import { Reservation } from 'src/app/helpers/models/reservation';
import { OrderRequest } from 'src/app/helpers/models/order';

export interface ReservationState {
    reservations: Array<OrderRequest>;
    reservationClick: OrderRequest;
    loading: boolean;
    loaded: boolean;
    error: boolean;
    sendReservation: boolean;
    failSendReservation: boolean;
}

const initState: ReservationState = {
    reservations: [],
    reservationClick: null,
    loading: false,
    loaded: false,
    error: false,
    sendReservation: false,
    failSendReservation: false,
};

export function reservationReducer( state = initState, action: fromReservation.actions): ReservationState {
    switch (action.type) {

        case fromReservation.SEND_RESERVATION_SUCCESS:
            return {
                ...state,
                sendReservation: false,
                failSendReservation: false,
            };

        case fromReservation.SEND_LOAD_RESERVATION:
            return {
                ...state,
                sendReservation: true,
                failSendReservation: false,
            };

        case fromReservation.SEND_LOAD_RESERVATION_FAIL:
            return {
                ...state,
                sendReservation: false,
                failSendReservation: true,
            };

        case fromReservation.SET_CLICK_RESERVATION:
            return {
                ...state,
                reservationClick: { ...action.reservationClick }
            };

        case fromReservation.UNSET_CLICK_RESERVATION:
            return {
                ...state,
                reservationClick: null
            };

        case fromReservation.LOAD_RESERVATION:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: false,
            };

        case  fromReservation.LOAD_RESERVATION_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: false
            };

        case fromReservation.LOAD_RESERVATION_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                reservations: [
                    ...action.reservation.map(res => {
                        return {
                            ...res
                        };
                    })
                ]
            };

        case fromReservation.ERROR_RESERVATION:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: true,
                reservations: null
            };

        case fromReservation.UNSET_RESERVATION:
            return {
                ...state,
                reservationClick: null,
                reservations: null
            };

        default:
            return state;
    }
}
