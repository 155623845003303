import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService) { }

  async canActivate() {
    const token = localStorage.getItem('isLoggedin');
    if (token === null) {
      this.router.navigate(['/login']);
      return false;
    } else {
      const currentUser = await this.authService.isAuth();
      if (currentUser) {
        return true;
      } else {
        this.router.navigate(['login']);
        return false;
      }
    }
  }

}
