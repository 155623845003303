import { Action } from '@ngrx/store';
import { User } from '../../helpers/models/user';

export const UNSET_USER = '[Auth] Unset User';

export const SET_USER = '[AUTH] Set User';

export class SetUserAction implements Action {
  readonly type = SET_USER;
  constructor(public user: User) {}
}

export class UnSetUserAction implements Action {
  readonly type = UNSET_USER;
}

export type actions = SetUserAction | UnSetUserAction;
