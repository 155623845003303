import { Action } from '@ngrx/store';
import { Filter } from 'src/app/helpers/models/filter';

export const LOAD_FILTERS = '[FILTER] Load Filter';
export const LOAD_FILTERS_FAIL = '[FILTER] Load Filter Fail';
export const LOAD_FILTERS_SUCCESS = '[FILTER] Load Filter Success';
export const UNSET_FILTERS = '[FILTER] Unset Filter';


export class LoadFilters implements Action {
    readonly type = LOAD_FILTERS;
}

export class LoadFiltersFail implements Action {
    readonly type = LOAD_FILTERS_FAIL;
}

export class LoadFilterSuccess implements Action {
    readonly type = LOAD_FILTERS_SUCCESS;
    constructor( public filters: Array<Filter>) {}
}

export class UnsetFilter implements Action {
    readonly type = UNSET_FILTERS;
}

export type actions =   LoadFilters |
                        LoadFiltersFail |
                        LoadFilterSuccess |
                        UnsetFilter;
