import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserObj } from 'src/app/helpers/models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/helpers/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { getKeyByValue } from 'src/app/helpers/get-key-by-value';


@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss']
})
export class UserInfoModalComponent implements OnInit {

  private _user: UserObj;

  get user(): UserObj {
    return this._user;
  }

  @Input()
  set user(val: UserObj) {
    this._user = val;
    this.patchFormControls();
  }

  username = new FormControl();
  firstName = new FormControl();
  lastName = new FormControl();
  email = new FormControl();
  phone = new FormControl();

  userFields = [this.username, this.firstName, this.lastName, this.email];
  profileFields = [this.phone];

  fieldMap = {
    first_name: this.firstName,
    last_name: this.lastName,
    username: this.username,
    phone: this.phone,
    email: this.email,
  };

  constructor(
    public modal: NgbActiveModal,
    private userService: UsersService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  async updateUser() {
    try {
      await this.updateUserFields();
      await this.updateProfileFields();
      this.toastr.success('Actualizado correctamente');
      this.modal.close();
    } catch (error) {
      this.toastr.error(error.toString());
    }
  }

  updateProperties(from: any, to: any) {
    for (const prop in from) {
      if (from.hasOwnProperty(prop)) {
        to[prop] = from[prop];
      }
    }
  }

  async updateUserFields() {
    const userData = this.dirtyData(this.userFields);
    await this.userService.patchUser(this.user.id, userData);
    this.updateProperties(userData, this.user);
  }

  async updateProfileFields() {
    const profileData = this.dirtyData(this.profileFields);
    await this.userService.patchProfile(this.user.id, profileData);
    this.updateProperties(profileData, this.user.profile);
  }

  filterDirty(fields: FormControl[]): FormControl[] {
    return fields.filter(field => field.dirty);
  }

  dirtyData(fields: FormControl[]): any {
    const data = {};
    this.filterDirty(fields).forEach(field => {
      const key = getKeyByValue(this.fieldMap, field);
      data[key] = field.value;
    });
    return data;
  }

  patchFormControls() {
    this.username.patchValue(this.user.username);
    this.firstName.patchValue(this.user.first_name);
    this.lastName.patchValue(this.user.last_name);
    this.email.patchValue(this.user.email);
    this.phone.patchValue(this.user.profile.phone);
  }

}
