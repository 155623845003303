export class User {
    firstName: string;
    lastName: string;
    email: string;
    expiry: string;
    token: string;

    constructor(user: UserObj) {
        this.firstName = user && user.first_name || null;
        this.lastName = user && user.last_name || null;
        this.email = user && user.email || null;
        this.expiry = user && user.expiry || null;
        this.token =  user && user.token || null;
    }

}

interface Profile {
    phone: string;
    photo: string;
    business_name: string;
    code: string;
}

export interface UserObj {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    expiry: string;
    token: string;
    profile: Profile;
}
