import { environment } from 'src/environments/environment';

class Constants {
    static SERVER_API = environment.server;
    static SERVER_API_MIGRATION = 'http://3.3.3.92:8000';
    static SERVER_API_MIGRATION_CLOUD = environment.server;
}

Object.seal(Constants);
export { Constants };
