import { Action } from '@ngrx/store';

export const MESSAGE_SUCCESS = '[MESSAGE] Message success';
export const MESSAGE_ERROR = '[MESSAGE] Message error';

export class SetMessageError implements Action {
    readonly type = MESSAGE_ERROR;
}

export class SetMessageSuccess implements Action {
    readonly type = MESSAGE_SUCCESS;
}

export type actions =  SetMessageError | SetMessageSuccess;
