import * as fromServices from '../actions/services.actions';
import { Service } from 'src/app/helpers/models/services';

export interface ServicesState {
    services: Array<Service>;
    loaded: boolean;
    loading: boolean;
    error: boolean;
}

const initState: ServicesState = {
    services: [],
    loaded: false,
    loading: false,
    error: false,
};

export function servicesReducer( state = initState, action: fromServices.actions): ServicesState {
    switch (action.type) {

        case fromServices.LOAD_SERVICES:
            return {
                ...state,
                loading: true,
            };

        case fromServices.LOAD_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                services: [
                    ...action.services.map(serv => {
                        return {
                            ...serv
                        };
                    })
                ],
            };

        case fromServices.LOAD_SERVICES_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: true,
            };

        case fromServices.UNSET_SERVICES:
            return {
                loaded: false,
                loading: false,
                services: null,
                error: false
            };

        default:
            return state;
    }
}
