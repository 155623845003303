import { Component, OnInit, OnDestroy } from '@angular/core';
import { Auth } from '../auth.interface';
import { AuthService } from '../../helpers/services/auth.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

@Component  ({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loading: boolean;
  private subscription: Subscription;
  errorMessage: string;
  year: number;

  constructor(private authService: AuthService,
              private store: Store<AppState>) { }

  ngOnInit() {
    this.subscription = this.store.select('ui')
      .subscribe( ui => {
        this.loading = ui.isLoading;
    });

    this.subscription = this.store.select('msg')
      .subscribe( message => {
        this.errorMessage = message.msg;
    });

    this.year = new Date().getFullYear();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit(data: Auth) {
    this.authService.logIn(data);
  }

}
